.column-row:hover{
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.1);
}

.column-row{
    transition: 0.3s;
}

.lighten{
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.2);
}

.beingSelected{
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.3);
}